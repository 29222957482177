import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Section } from 'app/components/Common/Section'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  cta?: ButtonProps
  description?: string
  languageCode: string
}

export const Info = memo(function Info({
  cta,
  description,
  languageCode,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Section label="info" languageCode={languageCode} />
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {cta ? (
          <FadeInUp>
            <CTA
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Restaurant Reservation',
                    })
                }
              }}
              variant="outline-dark"
              {...cta}
            />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 18.75rem;
  margin-bottom: 12.5rem;
  padding: 0 21.389vw;

  @media (max-width: 1199px) {
    margin-top: 12.5rem;
    margin-bottom: 9.375rem;
    padding: 0 13.889vw;
  }

  @media (max-width: 1023px) {
    margin-top: 9.375rem;
    margin-bottom: 6.25rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 767px) {
    margin-top: 6.25rem;
    margin-bottom: 4.6875rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 51.5rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  text-align: center;
  padding: 5rem 12.5rem;

  @media (max-width: 1199px) {
    padding: 3.75rem 9.375rem;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 6.25rem;
  }

  @media (max-width: 767px) {
    padding: 2.5rem 1.875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;

  @media (max-width: 1023px) {
    line-height: 1.75rem;
  }
`

const CTA = styled(Button)`
  margin-top: 1.875rem;

  @media (max-width: 1023px) {
    margin-top: 1.25rem;
  }

  @media (max-width: 479px) {
    &:after {
      content: none;
    }
  }
  @media (max-width: 374px) {
    letter-spacing: 0.1rem;
  }
`
